import classNames from "classnames";
import { type JSX, Show, splitProps, createEffect, mergeProps } from "solid-js";
import { FormLabel } from "~/components/ui/form-label";
import { Input } from "~/components/ui/input";
import { Textarea } from "~/components/ui/textarea";

type TextFieldProps = {
  name: string;
  type?: "text" | "email" | "tel" | "password" | "url" | "date" | undefined;
  label?: string | undefined;
  placeholder?: string | undefined;
  value: string | undefined;
  error: string;
  multiline?: boolean | undefined;
  required?: boolean | undefined;
  disabled?: boolean | undefined;
  ref: (element: HTMLInputElement | HTMLTextAreaElement) => void;
  onInput: JSX.EventHandler<HTMLInputElement | HTMLTextAreaElement, InputEvent>;
  onChange: JSX.EventHandler<HTMLInputElement | HTMLTextAreaElement, Event>;
  onBlur: JSX.EventHandler<HTMLInputElement | HTMLTextAreaElement, FocusEvent>;
};

export default function InputField2(props: TextFieldProps) {
  //   const [rootProps, inputProps] = splitProps(
  //     props,
  //     ["name", "value", "required", "disabled"],
  //     ["placeholder", "ref", "onInput", "onChange", "onBlur"]
  //   );

  const [inputProps] = splitProps(props, [
    "name",
    "placeholder",
    "ref",
    "required",
    "onInput",
    "disabled",
    "onChange",
    "onBlur",
  ]);
  const merged = mergeProps({ variant: "main" }, props);

  //   createEffect(() => {
  //     console.log(props.value, props.error);
  //   });

  return (
    <div class="relative flex flex-col items-start justify-start gap-2">
      <Show when={props.label}>
        <FormLabel for={props.name} class="block text-sm font-semibold text-gray-900 dark:text-white">
          {props.label}
        </FormLabel>
      </Show>

      <Show
        when={props.multiline}
        fallback={
          <Input
            {...inputProps}
            value={props.value}
            type={props.type}
            id={props.name}
            class={classNames({
              "bg-gray-50 border-gray-300 text-gray-900 text-sm block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500":
                true,
              "border rounded-lg focus:ring-blue-500 focus:border-blue-500": merged.variant === "main",
              "border-b rounded-none focus:ring-0": merged.variant === "sidebar",
            })}
          />
        }
      >
        <Textarea
          {...inputProps}
          value={props.value}
          id={props.name}
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          rows={4}
        />
      </Show>
      <div class="text-xs text-red-500">{props.error}</div>
    </div>
  );
}

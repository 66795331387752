import { getValues, required } from "@modular-forms/solid";
import { ChevronRight, PlusCircle, ScrollText, Wrench } from "lucide-solid";
import { Show, createEffect, createMemo } from "solid-js";
import InputField2 from "~/components/input/InputField2";
import { Select } from "~/components/input/Select";
import { Tabs } from "~/components/ui/tabs";
import { useWorkspace } from "~/context/WorkspaceProvider";
import ParametersAccordion from "./ParametersAccordion";

export default function AgentContent() {
  const [{ agentForm, Agent }, { workspaceSend }] = useWorkspace();

  const items = [
    {
      value: "julep-ai/samantha-1-turbo",
      label: "Samantha-1",
    },
  ];
  const hasInstruction = createMemo(() => {
    const values = getValues(agentForm);
    if (values.instructions && values.instructions.length > 0) {
      return true;
    }
    return false;
  });

  const hasTool = createMemo(() => {
    const values = getValues(agentForm);
    if (values.tools && values.tools.length > 0) {
      return true;
    }
    return false;
  });

  const vals = createMemo(() => {
    return getValues(agentForm);
  });

  return (
    <Tabs.Content value="agent" class="h-full">
      <div class="flex flex-col gap-6">
        <Agent.Field name="id" keepActive={true}>
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="name" keepActive={true} validate={[required("Please enter a name")]}>
          {(field, props) => (
            <InputField2
              {...props}
              type="text"
              label="Agent Name"
              placeholder="Name"
              value={field.value}
              error={field.error}
              required
            />
          )}
        </Agent.Field>
        <Agent.Field name="about" keepActive={true} validate={[required("Please enter a description")]}>
          {(field, props) => (
            <InputField2
              {...props}
              multiline={true}
              label="Description"
              placeholder="Description about the agent"
              value={field.value}
              error={field.error}
              required
            />
          )}
        </Agent.Field>
        <Agent.Field name="model">
          {(field, props) => (
            // <Select
            //   {...props}
            //   label="Model"
            //   placeholder="Select a model"
            //   items={items}
            //   required
            // />
            <></>
          )}
        </Agent.Field>
        <Agent.FieldArray name="instructions" keepActive={true}>
          {() => <></>}
        </Agent.FieldArray>
        <Agent.FieldArray name="tools" keepActive={true}>
          {() => <></>}
        </Agent.FieldArray>
        <Agent.Field name="default_settings.temperature" type="number">
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="default_settings.frequency_penalty" type="number">
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="default_settings.presence_penalty" type="number">
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="default_settings.repetition_penalty" type="number">
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="default_settings.length_penalty" type="number">
          {() => <></>}
        </Agent.Field>
        <Agent.Field name="default_settings.top_p" type="number">
          {() => <></>}
        </Agent.Field>
        <div>
          <div class="flex flex-row justify-between p-3 border-b border-gray-300">
            <div class="font-medium text-sm flex flex-row gap-2 items-center justify-center">
              <ScrollText class="w-4 h-4" /> Instruction
            </div>
            <div class="font-semibold text-sm">
              <button
                type="button"
                class="flex flex-row items-center justify-center gap-1"
                onClick={(_) => workspaceSend("INSTRUCTIONS")}
              >
                <Show
                  when={hasInstruction()}
                  fallback={
                    <>
                      <PlusCircle class="w-4 h-4" /> Add
                    </>
                  }
                >
                  View All <ChevronRight />
                </Show>
              </button>
            </div>
          </div>
          <div class="flex flex-row justify-between p-3 border-b border-gray-300">
            <div class="font-medium text-sm flex flex-row gap-2 items-center justify-center">
              <Wrench class="w-4 h-4" /> Tools
            </div>
            <div class="font-semibold text-sm">
              <button
                type="button"
                class="flex flex-row items-center justify-center gap-1"
                onClick={(_) => workspaceSend("TOOLS")}
              >
                <Show
                  when={hasTool()}
                  fallback={
                    <>
                      <PlusCircle class="w-4 h-4" /> Add
                    </>
                  }
                >
                  View All <ChevronRight />
                </Show>
              </button>
            </div>
          </div>
        </div>
        <ParametersAccordion />
      </div>
    </Tabs.Content>
  );
}

import { lineNumbers } from "@codemirror/view";
import { insert, remove } from "@modular-forms/solid";
import { langs } from "@uiw/codemirror-extensions-langs";
import { abcdef } from "@uiw/codemirror-themes-all";
import { ArrowLeft, Plus, Trash2 } from "lucide-solid";
import { createCodeMirror, createEditorControlledValue } from "solid-codemirror";
import { For, Match, Show, Switch } from "solid-js";
import { useWorkspace } from "~/context/WorkspaceProvider";

export default function ToolsContent() {
  const [{ Agent, agentForm, workspaceState, tempTool, inAgent }, { workspaceSend, setTempTool, resetTool }] =
    useWorkspace();

  const {
    editorView,
    ref: editorRef,
    createExtension,
  } = createCodeMirror({
    onValueChange: (value) => setTempTool(value),
  });

  createEditorControlledValue(editorView, tempTool);
  createExtension(abcdef);
  createExtension(langs.json());
  createExtension(() => lineNumbers());

  const saveTempTool = () => {
    const tool = JSON.parse(tempTool());
    console.log({ temp: tool });
    insert(agentForm, "tools", {
      value: {
        definition: tool.definition,
        type: tool.type,
      },
    });
  };

  return (
    <Agent.FieldArray name="tools" keepActive={inAgent()} keepState={inAgent()}>
      {(fieldarray) => (
        <Switch>
          <Match when={workspaceState.matches("ui.agent.tools.main")}>
            <div>
              <button
                onClick={(_) => workspaceSend("MAIN")}
                class="flex flex-row items-center justify-center gap-2 font-bold mb-4 mt-8"
              >
                <ArrowLeft size={20} /> Tools
              </button>
              <div class="text-sm mb-8">A higher temperature value typically makes the output more diverse.</div>

              <div class="uppercase text-xs border-b border-gray-300 pb-3 mb-3">Agent Tools</div>

              <For each={fieldarray.items}>
                {(_, index) => (
                  <>
                    <Agent.Field name={`tools.${index()}.definition.name`} keepActive={inAgent()} keepState={inAgent()}>
                      {(field, props) => (
                        <div class="flex flex-row justify-between  border-b border-gray-300 py-3">
                          <button type="button" class="text-sm font-medium hover:text-gray-400">
                            {field.value}()
                          </button>
                          <button class="cursor-pointer">
                            <Trash2
                              class="w-4 h-4 hover:stroke-red-400"
                              onClick={(_) =>
                                remove(agentForm, "tools", {
                                  at: index(),
                                })
                              }
                            />
                          </button>
                        </div>
                      )}
                    </Agent.Field>
                    <Agent.Field
                      name={`tools.${index()}.definition.description`}
                      keepActive={inAgent()}
                      keepState={inAgent()}
                    >
                      {(_) => <div></div>}
                    </Agent.Field>
                    <Agent.Field
                      name={`tools.${index()}.definition.parameters`}
                      keepActive={inAgent()}
                      keepState={inAgent()}
                    >
                      {(_) => <div></div>}
                    </Agent.Field>
                    <Agent.Field name={`tools.${index()}.type`} keepActive={inAgent()} keepState={inAgent()}>
                      {(_) => <div></div>}
                    </Agent.Field>
                  </>
                )}
              </For>

              <button
                type="button"
                onClick={() => {
                  workspaceSend("ADD_TOOLS");
                }}
                class="py-2.5 px-5 me-2 mb-2 mt-4 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-800 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex flex-row items-center justify-center gap-2"
              >
                <Plus class="h-4 w-4" /> Add Tool
              </button>
            </div>
          </Match>
          <Match when={workspaceState.matches("ui.agent.tools.add_tools")}>
            <div class="">
              <button
                onClick={(_) => workspaceSend("TOOLS_MAIN")}
                class="flex flex-row items-center justify-center gap-2 font-bold mb-4 mt-8"
              >
                <ArrowLeft size={20} /> Add a Tool
              </button>
              <div
                ref={editorRef}
                class="mt-6"
                style={{
                  height: "540px",
                  overflow: "scroll",
                }}
              />
              <Show when={workspaceState.matches("ui.agent.tools.add_tools")}>
                <div class="absolute bottom-0 left-0 w-full flex justify-between border-t border-gray-200 p-5 gap-5">
                  <button
                    type="button"
                    onClick={() => resetTool()}
                    class="w-full flex-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Revert Changes
                  </button>

                  <button
                    // type="submit"
                    type="button"
                    onClick={(_) => {
                      const tool = JSON.parse(tempTool());
                      const parameters = JSON.stringify(tool.definition.parameters);
                      insert(agentForm, "tools", {
                        value: {
                          definition: {
                            ...tool.definition,
                            parameters,
                          },
                          type: tool.type,
                        },
                      });
                      resetTool();
                      workspaceSend("TOOLS_MAIN");
                    }}
                    class="w-full flex-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                    // disabled={createEntityStatus.pending}
                  >
                    {/* <Show when={createEntityStatus.pending} fallback="Save">
                      <div class="mx-auto h-5 w-5 animate-spin rounded-full border-b-2 border-white" />
                    </Show> */}
                    Save
                  </button>
                </div>
              </Show>
            </div>
          </Match>
        </Switch>
      )}
    </Agent.FieldArray>
  );
}

import { createMachine, assign } from "xstate";
import {
  WorkspaceContext,
  WorkspaceEvent,
  // WorkspaceTypestate
} from "./types";

const workspaceMachine = createMachine<WorkspaceContext, WorkspaceEvent>(
  {
    id: "workspace",
    predictableActionArguments: true,
    initial: "agent",
    states: {
      agent: {
        on: {
          USER: "user",
        },
        type: "parallel",
        states: {
          ui: {
            initial: "main",
            states: {
              main: {
                on: {
                  INSTRUCTIONS: "instructions",
                  TOOLS: "tools",
                },
              },
              instructions: {
                on: {
                  MAIN: "main",
                },
              },
              tools: {
                on: {
                  MAIN: "main",
                },
                initial: "main",
                states: {
                  main: {
                    on: {
                      ADD_TOOLS: "add_tools",
                    },
                  },
                  add_tools: {
                    on: {
                      TOOLS_MAIN: "main",
                    },
                  },
                },
              },
            },
          },
          activity: {
            initial: "creating",
            states: {
              creating: {
                on: {
                  SELECT_AGENT: {
                    target: "updating",
                    actions: "selectAgent",
                  },
                },
              },
              updating: {
                on: {
                  CREATE_AGENT: {
                    target: "creating",
                  },
                  SELECT_AGENT: {
                    actions: "selectAgent",
                  },
                  CLEAR_AGENT: {
                    target: "creating",
                    actions: "clearAgent",
                  },
                },
              },
            },
          },
        },
      },
      user: {
        type: "parallel",
        on: { AGENT: "agent" },
        states: {
          ui: {},
          activity: {
            initial: "creating",
            states: {
              creating: {
                on: {
                  SELECT_USER: {
                    target: "updating",
                    actions: "selectUser",
                  },
                },
              },
              updating: {
                on: {
                  CREATE_USER: {
                    target: "creating",
                  },
                  SELECT_USER: {
                    actions: "selectUser",
                  },
                  CLEAR_USER: {
                    target: "creating",
                    actions: "clearUser",
                  },
                },
              },
            },
          },
        },
      },
    },
  }
  //   {
  //     actions: {
  //       selectAgent: assign({
  //         session: (context, event) => {
  //           if (event.type !== "SELECT_AGENT") return context.session;
  //           //   console.log("selecting agent, in machine");
  //           context.session.agentId = event.agentId;
  //           console.log("context", context.session);
  //           return context.session;
  //         },
  //       }),
  //       clearAgent: assign({
  //         session: (context, event) => {
  //           if (event.type !== "CLEAR_AGENT") return context.session;
  //           context.session.agentId = undefined;
  //           return context.session;
  //         },
  //       }),
  //       selectUser: assign({
  //         session: (context, event) => {
  //           if (event.type !== "SELECT_USER") return context.session;
  //           context.session.userId = event.userId;
  //           return context.session;
  //         },
  //       }),
  //       clearUser: assign({
  //         session: (context, event) => {
  //           if (event.type !== "CLEAR_USER") return context.session;
  //           context.session.userId = undefined;
  //           return context.session;
  //         },
  //       }),
  //     },
  //   }
);

export default workspaceMachine;

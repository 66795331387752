import { SliderProps } from "@ark-ui/solid";
import { Slider } from "../ui/slider";
import { Show } from "solid-js";
import { sentenceCase } from "change-case";

type Props = {
  min: number;
  max: number;
  name: string;
  step: number;
  value: number;
  onChange: (value: number) => void;
  tooltip?: string;
};

export default function Slider2(props: Props) {
  return (
    <Slider.Root
      onValueChange={(e) => props.onChange(e.value[0])}
      max={props.max}
      min={props.min}
      name={props.name}
      value={[props.value]}
      step={props.step}
    >
      <div class="flex flex-row justify-between px-3 py-4">
        <Slider.Label class="basis-2/5 text-sm font-medium">{sentenceCase(props.name).toUpperCase()}</Slider.Label>
        <div class="flex flex-row justify-between basis-3/5 gap-6">
          <Slider.Control class="w-[180px] basis-4/6">
            <Slider.Track class="h-[6px]">
              <Slider.Range class="bg-gray-400" />
            </Slider.Track>
            <Slider.Thumb index={0} class="border-gray-400 h-4 w-4 cursor-pointer" />
          </Slider.Control>
          <Slider.ValueText class="basis-1/6 text-right" />
        </div>
      </div>
    </Slider.Root>
  );
}

import { required } from "@modular-forms/solid";
import { createEffect, createMemo } from "solid-js";
import InputField from "~/components/input/InputField";
import InputField2 from "~/components/input/InputField2";
import { Tabs } from "~/components/ui/tabs";
import { useWorkspace } from "~/context/WorkspaceProvider";

export default function UserContent() {
  const [{ User }] = useWorkspace();

  return (
    <Tabs.Content value="user" class="f-full">
      <div class="flex flex-col gap-6">
        <User.Field name="id" keepActive={true}>
          {() => <></>}
        </User.Field>
        <User.Field name="name" keepActive={true} validate={[required("Please enter a name")]}>
          {(field, props) => (
            <InputField2
              {...props}
              type="text"
              label="User Name"
              placeholder="Name"
              value={field.value}
              error={field.error}
              required
            />
          )}
        </User.Field>
        <User.Field name="about" keepActive={true} validate={[required("Please enter a description")]}>
          {(field, props) => (
            <InputField2
              {...props}
              multiline={true}
              label="Description"
              placeholder="Description about the user"
              value={field.value}
              error={field.error}
              required
            />
          )}
        </User.Field>
      </div>
    </Tabs.Content>
  );
}

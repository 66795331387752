import { insert, remove } from "@modular-forms/solid";
import { ArrowLeft, Plus, Trash2 } from "lucide-solid";
import { For } from "solid-js";
import InputField2 from "~/components/input/InputField2";
import { useWorkspace } from "~/context/WorkspaceProvider";

export default function InstructionsContent() {
  const [{ agentForm, Agent }, { workspaceSend }] = useWorkspace();

  return (
    <div>
      <button
        onClick={(_) => workspaceSend("MAIN")}
        class="flex flex-row items-center justify-center gap-2 font-bold mb-4 mt-8"
      >
        <ArrowLeft size={20} /> Instructions
      </button>
      <div class="text-sm mb-8">A higher temperature value typically makes the output more diverse.</div>
      <div class="uppercase text-xs border-b border-gray-300 pb-3 mb-3">Agent Instructions</div>
      <Agent.FieldArray name="instructions" keepActive={true}>
        {(fieldArray) => (
          <>
            <For each={fieldArray.items}>
              {(_, index) => (
                <div class="relative">
                  <Agent.Field name={`instructions.${index()}.content`} keepActive={true}>
                    {(field, props) => (
                      <InputField2
                        {...props}
                        value={field.value}
                        error={field.error}
                        type="text"
                        placeholder="Enter content"
                        // variant="sidebar"
                        required
                      />
                    )}
                  </Agent.Field>
                  <button class="absolute right-2 top-2 cursor-pointer">
                    <Trash2
                      class="w-4 h-4 hover:stroke-red-400"
                      onClick={(_) =>
                        remove(agentForm, "instructions", {
                          at: index(),
                        })
                      }
                    />
                  </button>
                </div>
              )}
            </For>
            <button
              type="button"
              onClick={() =>
                insert(agentForm, "instructions", {
                  value: { content: "", important: false },
                })
              }
              class="py-2.5 px-5 me-2 mb-2 mt-4 text-xs font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-800 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700
          flex flex-row items-center justify-center gap-2
          "
            >
              <Plus class="h-4 w-4" /> Add Instruction
            </button>
          </>
        )}
      </Agent.FieldArray>
    </div>
  );
}

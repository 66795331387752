import { A } from "@solidjs/router";
import { Home } from "lucide-solid";
import WorkspaceChat from "~/components/workspace/chat/WorkspaceChat";
import WorkspaceSidebar from "~/components/workspace/sidebar";

export default function Workspace() {
  return (
    <div class="h-[calc(100vh-60px)] max-h-[calc(100vh-60px)]">
      <div class="flex flex-row items-center gap-2 justify-start border-b border-gray-200 p-4">
        <A href="/playground">
          <Home class="fill-black" />
        </A>
        <span class="font-semibold">Agent Workspace</span>
      </div>
      <div class="h-full flex flex-row">
        <WorkspaceSidebar />
        <WorkspaceChat />
      </div>
    </div>
  );
}

import { Match, Show, Suspense, Switch, createEffect, createSignal, lazy } from "solid-js";
import { Tabs } from "~/components/ui/tabs";
import { useWorkspace } from "~/context/WorkspaceProvider";
import { EntityForm } from "./types";
import AgentContent from "./AgentContent";
import UserContent from "./UserContent";
import InstructionsContent from "./InstructionsContent";
import ToolsContent from "./ToolsContent";
import { Dynamic } from "solid-js/web";
import classNames from "classnames";
import { useAction, useSubmission } from "@solidjs/router";
// import AgentSelect from "./AgentSelect";

const AgentSelect = lazy(() => import("./AgentSelect"));
const UserSelect = lazy(() => import("./UserSelect"));

export default function SidebarTabs() {
  const [selected, setSelected] = createSignal("agent");
  const [{ workspaceState, Agent, User }, { createEntityAction, updateEntityAction, workspaceSend }] = useWorkspace();
  const createEntity = useAction(createEntityAction);
  const createEntityStatus = useSubmission(createEntityAction);
  const updateEntity = useAction(updateEntityAction);
  const updateEntityStatus = useSubmission(updateEntityAction);

  const options = [
    {
      value: "agent",
      label: "Agent",
    },
    {
      value: "user",
      label: "User",
    },
  ];

  const handleSubmit = (values: EntityForm) => {
    if (workspaceState.matches("agent.activity.creating") || workspaceState.matches("user.activity.creating")) {
      return createEntity(values);
    } else {
      const { id, ...restData } = values;
      return updateEntity(id!, restData);
    }
  };

  createEffect(() => {
    console.log("create entity", createEntityStatus.pending);
  });

  const toggleTab = (value: string) => {
    if (value === "agent") {
      workspaceSend("AGENT");
    } else {
      workspaceSend("USER");
    }
  };

  return (
    <div class="h-full flex flex-col justify-between">
      <Dynamic
        component={selected() === "agent" ? Agent.Form : User.Form}
        onSubmit={handleSubmit}
        // onSubmit={(values) => console.log(values)}
        class="h-full flex flex-col justify-between gap-6"
      >
        <div class="relative p-5 h-full">
          <Tabs.Root
            value={selected()}
            onValueChange={(e) => {
              setSelected(e.value);
              toggleTab(e.value);
            }}
          >
            <Tabs.List class="flex justify-between gap-0 rounded-lg border border-gray-200">
              {options.map((option) => (
                <Tabs.Trigger
                  value={option.value}
                  class={classNames({
                    "flex-1 items-center justify-center py-2": true,
                    "bg-gray-200": selected() === option.value,
                    "bg-gray-50": selected() !== option.value,
                  })}
                >
                  {option.label}
                </Tabs.Trigger>
              ))}
              <Tabs.Indicator class="bg-gray-200" />
            </Tabs.List>
            <Switch>
              <Match when={workspaceState.matches("agent")}>
                <Suspense fallback={"Loading"}>
                  <AgentSelect />
                </Suspense>
              </Match>
              <Match when={workspaceState.matches("user")}>
                <Suspense fallback={"Loading"}>
                  <UserSelect />
                </Suspense>
              </Match>
            </Switch>
            <Switch>
              <Match when={workspaceState.matches("agent.ui.main")}>
                <AgentContent />
              </Match>
              <Match when={workspaceState.matches("user")}>
                <UserContent />
              </Match>
              <Match when={workspaceState.matches("agent.ui.instructions")}>
                <InstructionsContent />
              </Match>
              <Match when={workspaceState.matches("agent.ui.tools.main")}>
                <ToolsContent />
              </Match>
            </Switch>
          </Tabs.Root>
        </div>
        <Show when={workspaceState.matches("agent.ui.main") || workspaceState.matches("user")}>
          <div class="flex justify-between border-t border-gray-200 p-5 gap-5">
            <button class="w-full flex-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Revert Changes
            </button>
            <button
              type="submit"
              class="w-full flex-1 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              disabled={createEntityStatus.pending}
            >
              <Show
                when={!createEntityStatus.pending || !updateEntityStatus.pending}
                fallback={<div class="mx-auto h-5 w-5 animate-spin rounded-full border-b-2 border-white" />}
              >
                <Switch>
                  <Match
                    when={
                      workspaceState.matches("agent.activity.creating") ||
                      workspaceState.matches("user.activity.creating")
                    }
                  >
                    Save
                  </Match>
                  <Match
                    when={
                      workspaceState.matches("agent.activity.updating") ||
                      workspaceState.matches("user.activity.updating")
                    }
                  >
                    Update
                  </Match>
                </Switch>
              </Show>
            </button>
          </div>
        </Show>
      </Dynamic>
    </div>
  );
}

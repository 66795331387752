import { createForm, setValue, setValues } from "@modular-forms/solid";
import { action, cache, createAsync, useAction, useSubmission } from "@solidjs/router";
import { useMachine } from "@xstate/solid";
import { ParentProps, createContext, createEffect, createMemo, createSignal, useContext } from "solid-js";
import { createStore } from "solid-js/store";
// import { chat, createSession, listAgents, listUsers, updateAgent, updateUser } from "~/api/julep";
import {
  AdditionalInformation,
  AgentInstructions,
  AgentTools,
  ChatForm,
  ChatMLMessage,
  //   EntityForm,
  InputChatMlMessage,
  Session,
} from "~/components/workspace/sidebar/types";
import workspaceMachine from "~/lib/workspaceMachine";
import { Agent } from "~/components/workspace/sidebar/types";

const defaultUser = {
  id: "",
  name: "",
  about: "",
  additional_information: [],
};

const defaultAgent: Agent = {
  id: "",
  name: "",
  about: "",
  instructions: [],
  tools: [],
  model: "julep-ai/samantha-1-turbo",
  additional_information: [],
};

const defaultWorkspaceState = {
  tabValue: "agent",
  instructionsOpen: false,
  toolsOpen: false,
  addToolsOpen: false,
};

const defaultTool = `
{
  "type":"function",
  "definition":{
    "description":"string",
    "name":"string",
    "parameters":{}
  }
}
`;

const defaultSession: Session = {
  agentId: undefined,
  userId: undefined,
  sessionId: undefined,
  messages: [],
};

// export const echo = action(async (message: string) => {
//   await new Promise((resolve, reject) => setTimeout(resolve, 1000));
//   return message;
// }, "echo");

// const getAgentsAction = cache(async () => {
//   try {
//     const agents = await listAgents();
//     return agents;
//   } catch (error) {
//     throw error;
//   }
// }, "agents");

// const getUsersAction = cache(async () => {
//   try {
//     const users = await listUsers();
//     return users;
//   } catch (error) {
//     throw error;
//   }
// }, "users");

// export const route = {
//   load: () => getAgentsAction(),
// };

const useWorkspaceProvider = () => {
  const [userStore, setUserStore] = createStore(defaultUser);
  const [uiStore, setUIStore] = createStore(defaultWorkspaceState);
  const [sessionStore, setSessionStore] = createStore(defaultSession);

  const echo = action(async (message: string) => {
    await new Promise((resolve, reject) => setTimeout(resolve, 1000));
    return message;
  }, "echo");
  //   @ts-ignore
  const [workspaceState, workspaceSend] = useMachine(workspaceMachine);
  //   const [agentForm, Agent] = createForm<EntityForm>({
  //     initialValues: {
  //       name: undefined,
  //       about: undefined,
  //       model: "julep-ai/samantha-1-turbo",
  //       default_settings: {
  //         frequency_penalty: 0,
  //         temperature: 1,
  //         length_penalty: 1,
  //         presence_penalty: 0,
  //         repetition_penalty: 1,
  //         top_p: 1,
  //       },
  //     },
  //   });

  //   const [userForm, User] = createForm<EntityForm>({
  //     initialValues: {
  //       name: undefined,
  //       about: undefined,
  //     },
  //   });

  const [chatForm, Chat] = createForm<ChatForm>({
    initialValues: {
      role: "user",
      content: "",
    },
  });

  const [tempTool, setTempTool] = createSignal(defaultTool);

  //   const createEntityAction = action(async (data: any) => {
  //     try {
  //       if (workspaceState.matches("agent")) {
  //         const agent = await addAgent(data);
  //         setSessionStore("agentId", agent.id);
  //         workspaceSend("SELECT_AGENT");
  //         console.log({ agent });
  //       } else {
  //         const user = await addUser(data);
  //         setSessionStore("userId", user.id);
  //         workspaceSend("SELECT_USER");
  //         console.log({ user });
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     }
  //   }, "createEntity");

  //   const updateEntityAction = action(async (id: string, data: any) => {
  //     try {
  //       if (workspaceState.matches("agent")) {
  //         const agent = await updateAgent({ agentId: id, data });
  //         console.log({ agent });
  //       } else {
  //         const user = await updateUser({ userId: id, data });
  //         console.log({ user });
  //       }
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     }
  //   }, "updateEntity");

  //   const sendMessageAction = action(async (message: ChatForm) => {
  //     if (!sessionStore.agentId || !sessionStore.userId) return;
  //     const sentMessage = {
  //       ...message,
  //       name: currentUser()!.name,
  //       role: "user",
  //     } as ChatMLMessage;

  //     setSessionStore("messages", [...sessionStore.messages, sentMessage]);

  //     try {
  //       if (!sessionStore.sessionId) {
  //         const session = await createSession({
  //           agentId: sessionStore.agentId!,
  //           userId: sessionStore.userId!,
  //         });

  //         setSessionStore("sessionId", session.id);
  //       }

  //       const reply = await chat(sessionStore.sessionId!, {
  //         messages: sessionStore.messages,
  //       });

  //       setValues(chatForm, {
  //         content: "",
  //       });

  //       setSessionStore("messages", [...sessionStore.messages, { ...reply.response[0][0], name: currentAgent()?.name }]);
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     }
  //   }, "sendMessage");

  //   createEffect(() => {
  //     console.log("status origin", sendMessageStatus);
  //   });
  //   const agents = createAsync(() => getAgentsAction(), {
  //     deferStream: true,
  //   });
  //   const users = createAsync(() => getUsersAction(), {
  //     deferStream: true,
  //   });

  //   const currentAgent = createMemo(() => agents()?.items.find((agent) => agent.id === sessionStore.agentId));
  //   const currentUser = createMemo(() => users()?.items.find((user) => user.id === sessionStore.userId));

  const inUser = createMemo(() => workspaceState.matches("user"), false);
  const inAgent = createMemo(() => workspaceState.matches("agent"), false);

  //   const selectAgent = (agentId: string) => {
  //     const selectedAgent = agents()?.items.find((agent) => agent.id === agentId);
  //     setSessionStore("agentId", agentId);
  //     workspaceSend("SELECT_AGENT");
  //     setValues(agentForm, {
  //       ...selectedAgent,
  //     });
  //   };

  //   const clearAgent = () => {
  //     workspaceSend("CLEAR_AGENT");
  //     setSessionStore("agentId", undefined);
  //     setValues(agentForm, {
  //       ...defaultAgent,
  //     });
  //   };

  //   const selectUser = (userId: string) => {
  //     const selectedUser = users()?.items.find((user) => user.id === userId);
  //     setSessionStore("userId", userId);
  //     workspaceSend("SELECT_USER");
  //     setValues(userForm, {
  //       ...selectedUser,
  //     });
  //   };

  //   workspaceSend("CLEAR_USER");
  //   const clearUser = () => {
  //     setSessionStore("userId", undefined);
  //     setValues(userForm, {
  //       ...defaultUser,
  //     });
  //   };

  //   createEffect(() => {
  //     console.log(workspaceState.value);
  //     console.log("matches", workspaceState.matches("agent.activity.creating"));
  //   });

  return [
    {
      userStore,
      uiStore,
      workspaceState,
      //   userForm,
      //   agentForm,
      //   User,
      //   Agent,
      tempTool,
      inUser,
      inAgent,
      chatForm,
      Chat,
      //   agents,
      //   users,
      sessionStore,
      //   sendMessageStatus,
    },
    {
      setUserStore,
      setUIStore,
      workspaceSend,
      setTempTool,
      resetTool: () => setTempTool(defaultTool),
      resetForm: () => {},
      //   setSessionStore,
      //   selectAgent,
      //   clearAgent,
      //   selectUser,
      //   clearUser,
      //   createEntityAction,
      //   updateEntityAction,
      //   sendMessageAction,
    },
  ] as const;
};

type WorkspaceContextType = ReturnType<typeof useWorkspaceProvider>;

export const WorkspaceContext = createContext<WorkspaceContextType>();

export const WorkspaceProvider = (props: ParentProps) => {
  const value = useWorkspaceProvider();

  return <WorkspaceContext.Provider value={value}>{props.children}</WorkspaceContext.Provider>;
};

export const useWorkspace = () => useContext(WorkspaceContext)!;

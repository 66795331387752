import { Accordion } from "@kobalte/core";
import "./style.css";
import { ChevronDownIcon } from "lucide-solid";
import { Slider } from "~/components/ui/slider";
import { useWorkspace } from "~/context/WorkspaceProvider";
import Slider2 from "~/components/input/Slider2";
import { insert, setValue } from "@modular-forms/solid";

export default function ParametersAccordion() {
  const [{ agentForm, Agent }] = useWorkspace();
  return (
    <Accordion.Root class="accordion border-none w-full" collapsible>
      <Accordion.Item class="accordion__item" value="item-1">
        <Accordion.Header class="accordion__item-header">
          <Accordion.Trigger class="accordion__item-trigger p-3">
            <span class="text-xs font-normal uppercase">Parameters</span>
            <ChevronDownIcon class="accordion__item-trigger-icon w-4 h-4" aria-hidden />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content class="accordion__item-content flex flex-col divide-y">
          <Agent.Field name="default_settings.temperature" type="number">
            {(field, props) => (
              <Slider2
                name="temperature"
                min={0}
                max={2}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.temperature", value)}
              />
            )}
          </Agent.Field>
          <Agent.Field name="default_settings.frequency_penalty" type="number">
            {(field, props) => (
              <Slider2
                name="frequency_penalty"
                min={-2}
                max={2}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.frequency_penalty", value)}
              />
            )}
          </Agent.Field>
          <Agent.Field name="default_settings.presence_penalty" type="number">
            {(field, props) => (
              <Slider2
                name="presence_penalty"
                min={-1}
                max={1}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.presence_penalty", value)}
              />
            )}
          </Agent.Field>
          <Agent.Field name="default_settings.repetition_penalty" type="number">
            {(field, props) => (
              <Slider2
                name="repetition_penalty"
                min={0}
                max={2}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.repetition_penalty", value)}
              />
            )}
          </Agent.Field>
          <Agent.Field name="default_settings.length_penalty" type="number">
            {(field, props) => (
              <Slider2
                name="length_penalty"
                min={0}
                max={2}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.length_penalty", value)}
              />
            )}
          </Agent.Field>

          <Agent.Field name="default_settings.top_p" type="number">
            {(field, props) => (
              <Slider2
                name="top_p"
                min={0}
                max={1}
                step={0.1}
                value={field.value!}
                onChange={(value) => setValue(agentForm, "default_settings.top_p", value)}
              />
            )}
          </Agent.Field>
        </Accordion.Content>
      </Accordion.Item>
      <Accordion.Item class="accordion__item" value="item-1"></Accordion.Item>
    </Accordion.Root>
  );
}

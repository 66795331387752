import { getValue, setValue } from "@modular-forms/solid";
import { ChevronDown, ChevronUp, PlusCircle, SendHorizonal, Trash, Trash2, X } from "lucide-solid";
import { For, Index, Show, createEffect } from "solid-js";
import { Portal } from "solid-js/web";
import AddMessage from "~/components/playground/AddMessage";
import PlaygroundMessage from "~/components/playground/PlaygroundMessage";
// import { Select } from "~/components/ui/select";
import { Textarea } from "~/components/ui/textarea";
import { useJulep } from "~/context/JulepProvider";
import { useWorkspace } from "~/context/WorkspaceProvider";
import { ChatForm } from "../sidebar/types";
import { createToaster } from "@ark-ui/solid";
import * as Toast from "~/components/ui/toast";
import { useAction, useSubmission } from "@solidjs/router";

export default function WorkspaceChat() {
  const [{ loading, isContinue }, { submitAction, clearConversation, toggleIsContinue }] = useJulep();
  const [{ Chat, chatForm, sessionStore }, { sendMessageAction }] = useWorkspace();

  const submit = useAction(submitAction);
  const submitStatus = useAction(submitAction);

  const sendMessageStatus = useSubmission(sendMessageAction);
  const items = [
    { label: "User", value: "user" },
    { label: "Thought", value: "thought" },
    { label: "Information", value: "information" },
  ];

  const [Toaster, toast] = createToaster({
    placement: "top",
    render(toast) {
      return (
        <Toast.Root>
          <Toast.Title>{toast().title}</Toast.Title>
          <Toast.Description>{toast().description}</Toast.Description>
          <Toast.CloseTrigger>
            <X class="w-4 h-4" />
          </Toast.CloseTrigger>
        </Toast.Root>
      );
    },
  });

  const handleSubmit = (values: ChatForm) => {
    // if (!sessionStore.agentId || !sessionStore.userId) {
    //   return toast().error({
    //     title: "Please select an agent and/or a user",
    //   });
    // }
    // if (getValue(chatForm, "content") === "") {
    //   return toast().error({
    //     title: "Message content cannot be empty",
    //   });
    // }
    // sendMessage(values);
  };

  createEffect(() => {
    // console.log("sending", echoStatus.pending);
  });

  return (
    <div class="flex grow flex-col justify-between md:grow">
      <Toaster />
      <div
        class="my-4 flex flex-col items-stretch overflow-y-auto px-4"
        // ref={messageContainer}
      >
        {/* <button class="border border-gray-200 bg-gray-50 rounded-lg p-4">
          <span class="flex flex-row items-center text-sm gap-1">
            <PlusCircle class="w-4 h-4" /> Situation
          </span>
        </button> */}
        <div class="flex flex-col gap-9 mt-10 ml-12 overflow-y-auto">
          <For each={sessionStore.messages}>
            {(message) => (
              <div class="flex flex-col gap-1">
                <div class="text-xs font-semibold">{message.name}</div>
                <div class="text-sm">{message.content}</div>
              </div>
            )}
          </For>
        </div>
      </div>
      <Chat.Form onSubmit={handleSubmit}>
        <div class="flex flex-col justify-between gap-3 bg-white py-4 px-12">
          {/* <ClearConversationModal /> */}
          <div class="w-40">
            {/* <Chat.Field name="role">
              {(field, props) => (
                <Select.Root
                  items={items}
                  value={[field.value!]}
                  onValueChange={(e) => setValue(chatForm, "role", e.value[0])}
                  positioning={{
                    placement: "top-start",
                  }}
                >
                  <Select.Control>
                    <Select.Trigger>
                      <Select.ValueText />
                      <Select.Indicator>
                        <ChevronUp />
                      </Select.Indicator>
                    </Select.Trigger>
                  </Select.Control>
                  <Portal>
                    <Select.Positioner>
                      <Select.Content>
                        <Select.ItemGroup id="framework">
                          <Index each={items}>
                            {(item) => (
                              <Select.Item item={item().value}>
                                <Select.ItemText>{item().label}</Select.ItemText>
                                <Select.ItemIndicator>✓</Select.ItemIndicator>
                              </Select.Item>
                            )}
                          </Index>
                        </Select.ItemGroup>
                      </Select.Content>
                    </Select.Positioner>
                  </Portal>
                </Select.Root>
              )}
            </Chat.Field> */}
          </div>
          <div>
            <Chat.Field name="content">
              {(field, props) => (
                <Textarea
                  {...props}
                  class="border border-gray-200"
                  placeholder="Write text here..."
                  id="message"
                  rows={5}
                />
              )}
            </Chat.Field>
          </div>
          <div class="flex flex-row items-center justify-between">
            {/* <button class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 flex flex-row gap-2 items-center">
              <Trash2 class="w-4 h-4" />
              <span class="text-xs font-medium">Clear Conversation</span>
            </button> */}
            <div></div>
            <button
              type="submit"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 flex flex-row gap-2 items-center disabled:opacity-10 disabled:cursor-not-allowed"
              disabled={sendMessageStatus.pending}
            >
              <SendHorizonal class="w-4 h-4" />
              <span class="text-xs font-medium">Submit</span>
            </button>
          </div>
        </div>
      </Chat.Form>
    </div>
  );
}
